<template>
  <div>
    <div id="loginbox">
      <img src="@/assets/img/Special/post.png" />

      <van-form class="lform" ref="form" @submit="submit">
        <van-field
          class="lform-input key"
          placeholder="请输入兑换码"
          v-model="form.redeCodeNo"
          :rules="[
            {
              required: true,
              message: '请输入兑换码'
            }
          ]"
          @blur="getPhone"
        />
        <van-field
          class="lform-input phone"
          placeholder="输入兑换码后自动显示"
          v-model="form.phone"
          name="phone"
          disabled
          :rules="[
            {
              required: true,
              validator: val => /^1[3|4|5|6|7|8|9][0-9]{9}$/.test(val),
              message: '请填写正确的手机号'
            }
          ]"
        />
        <van-field
          class="lform-input yzm"
          placeholder="请输入验证码"
          v-model="form.code"
          :rules="[
            {
              required: true,
              message: '请输入验证码'
            }
          ]"
        >
          <template #button>
            <div class="cdown" v-if="!time" @click="sendCode">获取验证码</div>
            <van-count-down
              class="cdown"
              v-else
              :time="time"
              @finish="time = 0"
            />
          </template>
        </van-field>
        <van-button class="submit" :loading="loading" native-type="submit"
          >去兑换</van-button
        >
      </van-form>
      <div class="lform explain">
        <div class="tips">兑换说明</div>
        <div>
          1、输入兑换码，购买时的手机号码会自动显示，获取验证码后点击兑换；<br />
          2、请在有效期内使用，过期将无法使用，不退换不补偿；<br />
          3、若兑换后显示库存不足，请重新兑换或联系客服，客服电 话：
          <a href="tel:4006627299">4006627299</a>；
        </div>
      </div>
    </div>
    <div class="overlay" v-show="showDialog">
      <div class="center">
        <img src="@/assets/img/Special/warm.png" />
        <h1>温馨提示</h1>
        <div>
          该兑换码已兑换，若需要查看已兑换的券码，请输入
          <span>首次兑换时的手机号码</span>
        </div>
        <button @click="showDialog = false">好的</button>
      </div>
    </div>
  </div>
</template>

<script>
import { syncVerify, sendCode, getSpecialInfo } from '@/api/Special.js'
export default {
  data() {
    return {
      time: 0,
      loading: false,
      showDialog: false,
      form: {
        redeCodeNo: undefined,
        phone: undefined,
        code: null
      }
    }
  },
  methods: {
    async getPhone() {
      const { data, code, message } = await getSpecialInfo({
        redeCodeNo: this.form.redeCodeNo
      })
      this.form.phone = data?.sendPhone || undefined
      if (code !== 0) {
        this.$toast({
          message: message
        })
      }
    },
    async submit() {
      try {
        this.loading = true
        const { code, data, message } = await syncVerify({
          ...this.form,
          redeCodeType: 6, // 兑换码类型，默认传6
          redeemCodePageType: 4
        })
        this.loading = false
        switch (code) {
          case 0:
            this.$router.push({
              path: '/SpecialList',
              query: data
            })
            break
          case 1:
            this.showDialog = true
            break
          case 2:
            this.$router.push({
              path: '/SpecialOutStock'
            })
            break
          case 3:
            this.$router.push({
              path: '/SpecialCrowded'
            })
            break
          default:
            this.$toast.fail({
              message: message
            })
            break
        }
      } catch (error) {
        this.loading = false
      }
    },
    sendCode() {
      this.$refs.form.validate('phone').then(async() => {
        const { code, message } = await sendCode({
          redeCodeNo: this.form.redeCodeNo,
          redeCodeType: 6 // 兑换码类型，默认传6
        })
        if (code === 0) {
          this.time = 60000
        } else {
          this.$toast.fail({
            message: message
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/assets/style/Special.less';
</style>
